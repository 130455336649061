import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import logo_uni from '../images/logo_uanl.png';

const Nav = () => {

    const navigate = useNavigate();
    
    return (
        <div className="logo_top">
            <img className="logo_uni" src={logo_uni} alt="Logo"/>
            <img className="logo_ev" src={logo} alt="Logo"/>
            <div className="clear"></div>
        </div>

    )


}

export default Nav;