import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import like_icon from '../images/like_icon.png';
import dislike_icon from '../images/dislike_icon.png';
import sad_icon from '../images/sad_icon.png';

const Feed = () => {

    const navigate = useNavigate();
    
    //
    const { getFilterfeed, filterfeed, saveLikes, saveDislikes, saveSads, nextFilterquestions, lastfilterreg } = useContext(DataContext);

    //NEXT PAGE
    async function nextP(){
        if(lastfilterreg == undefined){
            console.log("Init")
        }else{
            await nextFilterquestions();
        }  
    }

    //DRAW LIKES
    function setLikes(a, b){
        var x = document.getElementById('l' + a);
        x.innerHTML = b;
        x.classList.add('bounceIn')
        saveLikes(a, b);
    }

     //DRAW DISLIKES
     function setDislikes(a, b){
        var x = document.getElementById('d' + a);
        x.innerHTML = b;
        x.classList.add('bounceIn')
        saveDislikes(a, b);
    }

    //DRAW SADS
    function setSads(a, b){
        var x = document.getElementById('s' + a);
        x.innerHTML = b;
        x.classList.add('bounceIn')
        saveSads(a, b);
    }

    //GO CREATE
    function goCreate(){
        navigate('/Create');
    }

    //USE EFFECT
    useEffect(() => {
       getFilterfeed();
    },[]);

    
    
    return (
        <div className="main">
            
            <h1>Feed</h1>
            <button className="debug_btn" onClick={getFilterfeed}>CHECK</button>

            <button className="call_btn" onClick={goCreate}>HAZ UNA PREGUNTA O COMENTARIO</button>
            <div className="main_boxes">
                {
                    filterfeed.length == 0
                    ?
                    <p className="nodata_msg">No hay publicaciones.</p>
                    :
                    filterfeed.map((item, index) => (
                        <div className="question_box" key={index}>

                            <div className="question_txt">
                                <p className="question_st">{item.category}</p>
                                <p className="question_mt">{item.question}</p>
                            </div>
                            
                            <div className="question_likes">
                                <div className="question_btn" onClick={() => setLikes(item.id, item.likes + 1)}>
                                    <img src={like_icon} alt=""/>
                                    <p id={"l" + item.id} className="animated">{item.likes}</p>
                                </div>
                                <div className="question_btn" onClick={() => setDislikes(item.id, item.dislikes + 1)}>
                                    <img src={dislike_icon} alt=""/>
                                    <p id={"d" + item.id} className="animated">{item.dislikes}</p>
                                </div>
                                <div className="question_btn" onClick={() => setSads(item.id, item.sad + 1)}>
                                    <img src={sad_icon} alt=""/>
                                    <p id={"s" + item.id} className="animated">{item.sad}</p>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="question_res">
                                <p className="res_tt">Respuesta:</p> 
                                <p className="res_txt">{item.response ? item.response : "La pregunta aún no ha sido respondida."}</p>    
                            </div>
                        </div>
                ))}
                <button className="load_btn" onClick={nextP}>CARGAR MÁS</button>
            </div>
            
        </div>

    )


}

export default Feed;