import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import { DataContext } from '../firebase/datacontext.js';

const Create = () => {

    //NAV
    const navigate = useNavigate();

    //IMPORT FUNCTIONS
    const { saveQuestion } = useContext(DataContext);
    
    //STATES
    const [question, setQuestion] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [category, setCategory] = useState('');

    //CHECK INPUTS
    async function submitQuestion(){
        var w = document.getElementById('category');
        var x = document.getElementById('name');
        var y = document.getElementById('email');
        var z = document.getElementById('question');

        var c = document.getElementById('captch').value;

        var a =  document.getElementById('pop1');

        var tags = [y.value.toLowerCase()].concat(x.value.split(' ').map(word => word.toLowerCase()), w.value.split(' ').map(word => word.toLowerCase()) );

        // Expresión regular para letras
        var lettersPattern = /^[a-zA-ZÁÉÍÓÚáéíóú\s]*$/;
        
        // Expresión regular para email (solo una arroba)
        var emailPattern = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        
        // Expresión regular para letras, números y signos de interrogación
        var alphanumericQuestionPattern = /^[a-zA-Z0-9?!\sÁÉÍÓÚáéíóú¿¡]*$/;

        if (validateCaptcha(c)==true) {
            if(x.value.match(lettersPattern) && y.value.match(emailPattern) && z.value.match(alphanumericQuestionPattern) && x.value !== '' && y.value !== '' && z.value !== ''){
                await saveQuestion(name, email, category, question, tags);
                x.value = '';
                y.value = '';
                z.value = '';
                c = '';
                a.style.display = "flex";
            }
            else{
                alert('Por favor, verifica los campos.');
            }
        }
   
        else {
            alert('Captcha no coincide');
        }

    }

    //GO FEED
    function goFeed(){
        navigate('/Feed');
    }

    //USE EFFECT
    useEffect(() => {
        loadCaptchaEnginge(6);
     },[]);



    
    return (
        <>
        <div className="popup" id="pop1">
            <div className="pop_box">
                <h3>¡Gracias por enviar tu pregunta!</h3>
                <p>Tu pregunta será respondida y publicada en la plataforma.</p>
                <button className="pop_btn" onClick={goFeed}>ACEPTAR</button>
            </div>
        </div>

        <div className="main">

           

            <h1>Haz una pregunta o comentario</h1>

            <div className="msg_box">
                
                <div className="msg_field">
                    <label htmlFor="name">Nombre:</label>
                    <input type="text" id="name" name="name" maxLength="60" onChange={e => setName(e.target.value)}/>
                </div>
                <div className="msg_field">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" maxLength="30" onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="msg_field">
                    <label htmlFor="category">Elige una categoría</label>
                    <select id="category" name="category" onChange={e => setCategory(e.target.value)}>
                        <option value="">Categorías:</option>
                        <option value="Infraestructura">Infraestructura</option>
                        <option value="Cafetería">Cafetería</option>
                        <option value="Personal docente">Personal docente</option>
                        <option value="Trámites">Trámites</option>
                    </select>   
                </div>
                <div className="msg_field">
                    <label htmlFor="question">Escribe tu pregunta o comentario</label>
                    <input type="text" id="question" name="question" maxLength="200" onChange={e => setQuestion(e.target.value)}/>
                </div>

                <LoadCanvasTemplateNoReload />
                <div className="msg_field">
                    <label htmlFor="captch">Escribe el captcha</label>
                    <input type="text" id="captch" name="captch" maxLength="6"/>
                </div>

                <button className="inside_btn" onClick={submitQuestion}>ENVIAR PREGUNTA</button>
            
            </div>

           
            
        </div>
        </>
    )


}

export default Create;