import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';


const Intro = () => {

    const navigate = useNavigate();
    
    return (
        <div className="main2">
            <div className="vid_container">
                <h1 className="err_link">404</h1>
            </div>
        </div>

    )

}

export default Intro;